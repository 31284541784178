import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import PuffLoader from "react-spinners/PuffLoader";

const ApiLoader = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-white bg-opacity-50 z-50">
        <PuffLoader color={"#0ABAB5"} loading={true} size={100} />
      </div>
    )
  );
};

export default ApiLoader;
